import React, { useState,useLayoutEffect } from 'react';
import MetaTags from 'react-meta-tags';
import 'react-image-lightbox/style.css';

import "../Css/Gallery.css";
import mauli1 from "../Images/Hammocks-1.jpg";
import maul2 from "../Images/Hammocks-6.jpg";
import mauli3 from "../Images/Maulivillage-Building-3.jpg";
import maul4 from "../Images/Outdoors-Lawn-Garden-Hammocks-2.jpg";
import mauli5 from "../Images/River-View-Outdoors-5.jpg";
import mauli6 from "../Images/River-View-Outdoors-6-1.jpg";
import mauli7 from "../Images/Swimming-Pool-1.jpg";
import mauli8 from "../Images/Swimming-Pool-2.webp";
import mauli9 from "../Images/Swimming-Pool-3.webp";
import mauli10 from "../Images/Treehouse-Ext-10-1.jpg";
import mauli11 from "../Images/chair.jpg";
import mauli12 from "../Images/mauli-banner-1.webp";
import mauli13 from "../Images/mauli-banner-2.webp";
import mauli14 from "../Images/mauli-banner-3.webp";
import mauli15 from "../Images/mauli-banner-3.webp";
import mauli16 from "../Images/mauli-banner-about-us.webp";
import TabsGallery from '../Pages/TabsGallery';
const Gallery = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
    // ========== Smile Gallery ========
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [
    mauli1,
  maul2,
  mauli3,
  maul4,
  mauli5,
  mauli6,
  mauli7,
  mauli8,
  mauli9,
  mauli10,
  mauli11,
  mauli12,
  mauli13,
  mauli14,
  mauli15,
  mauli16,
];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  return (
    <div>
    <MetaTags>
      <title>Best Resorts with Private Pool in Alibaug | Mauli Village Resort</title>
        <meta title="Best Resorts with Private Pool in Alibaug | Mauli Village Resort" />
        <meta
          name="description"
          content="Unwind in style at the Best Resorts with Private Pools in Alibaug. Book your getaway for a perfect blend of tranquility and luxury. "
        />
        <meta
          name="keywords"
          content="Top Resorts in Alibaug, Resorts For Family in Alibaug,Budget-Friendly Resorts in Alibaug,Best Resorts with Private Pool in Alibaug,Best Resorts in Alibaug with Food,Best Resorts Near Alibaug"
        />
        <link rel="canonical" href="https://maulivillage.com/best-resorts-private-pool-alibaug" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Resorts with Private Pool in Alibaug | Mauli Village Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maulivillage.com/best-resorts-private-pool-alibaug"/>
        <meta property="og:description" content="Unwind in style at the Best Resorts with Private Pools in Alibaug. Book your getaway for a perfect blend of tranquility and luxury. " />
        <meta property="og:image" content="https://maulivillage.com/" />       
      </MetaTags>
    
    <div className="page-wrapper">
    <section
      className="page-title"
      style={{
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${mauli16})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "100% 100%"
  }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Best Resorts with Private Pool in Alibaug</h1>
          <p className='d-none'> Best Resorts with Private Pool in Alibaug</p>
        </div>
      </div>
    </section>
    <section>
    <section className="news-section pt-0 light-bg mx-60 border-shape-bottom pt-4">
  <div className="auto-container">
    <div className="title-box text-center">
      <div className="sub-title">Gallery </div>
      <h2 className="sec-title"> Our Gallery</h2>
      <p className='d-none'> Best Resorts with Private Pool in Alibaug</p>
    </div>
    <p className='d-none'> Best Resorts with Private Pool in Alibaug</p>
    <TabsGallery />
    
  </div>
</section>
      <p className='seo-para'>
        Indulge in the epitome of luxury at Mauli Resort, distinguished among the Best Resorts with Private Pool in Alibaug. Our exclusive gallery showcases the opulent experience that awaits, featuring a collection of images that capture the essence of our private pool accommodations.

        As you explore the gallery, immerse yourself in the allure of our meticulously designed private pool villas. Each image reflects the sophistication and comfort that define Mauli Resort as one of the Best Resorts with Private Pool in Alibaug. Enjoy a visual journey through the tranquil retreat spaces, where privacy and serenity seamlessly merge.

        These captivating visuals underscore the uniqueness of our private pool offerings, illustrating the perfect blend of elegance and relaxation. The gallery provides a sneak peek into the lush surroundings, inviting you to envision your retreat in one of the Best Resorts with Private Pool in Alibaug.

        Whether it's a romantic getaway or a family vacation, our private pool accommodations promise an exclusive and rejuvenating experience. With the repeated assurance that Mauli Resort stands as one of the Best Resorts with Private Pool in Alibaug, the gallery section serves as a testament to our commitment to providing unparalleled luxury in every aspect of your stay.

        As you peruse the images, envision yourself immersed in the crystal-clear waters of your private pool, surrounded by the serene ambiance that characterizes Mauli Resort. This gallery invites you to step into a world where luxury meets tranquility, making your Alibaug retreat a truly unforgettable experience.
      </p>

    </section>
  </div>
    </div>
  )
}

export default Gallery