import React from 'react'
import { Pagination, Navigation } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import banner1 from "../Images/mauli-banner-1.webp";
import banner2 from "../Images/mauli-banner-2.webp";
import banner3 from "../Images/mauli-banner-1.webp";
const Slider = () => {
  return (
    <>
         <Swiper
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[ Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div
        className="swiper-slide"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${banner1})`

        }}
      >
        <div className="content-outer">
          <div className="content-box">
            <div className="inner">
              <h2 className="banner-title">
              Discover refined serenity <br/> at Mauli Village
                
              </h2>
              <div className="text">
              A haven where opulence meets nature.
              </div>
            </div>
          </div>
        </div>
      </div>
        </SwiperSlide>
        <SwiperSlide>
        <div
        className="swiper-slide"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${banner2})`
        }}
      >
        <div className="content-outer">
          <div className="content-box">
            <div className="inner">
              <h2 className="banner-title">
              Indulge in the lap of luxury <br/> at Mauli Village
              </h2>
              <div className="text">
              Alibaug's exclusive retreat blends sophistication with nature's beauty for an unparalleled experience.
              </div>
            </div>
          </div>
        </div>
      </div>
        </SwiperSlide>
        <SwiperSlide>
        <div
        className="swiper-slide"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${banner3})`
        }}
      >
        <div className="content-outer">
          <div className="content-box">
            <div className="inner">
              <h2 className="banner-title">
              Escape to Mauli Village
              </h2>
              <div className="text">
              Where opulence awaits, immerse yourself in the serene surroundings of Alibaug—a perfect <br/> blend of luxury and nature's allure.
              </div>
            </div>
          </div>
        </div>
      </div>
        </SwiperSlide>
      </Swiper>
    </>
  )
}

export default Slider