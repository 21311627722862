import React from 'react'
import '../Pages/Css/Faq.css';
import { Collapse } from 'antd';
import banner2 from "../Images/mauli-banner-2.webp";
const items = [
  {
    key: '1',
    label: 'What are the amenities do you offer',
    children: <p>We offer free Wi-Fi, Swimming Pool, Baby Pool, Tennis Court, Cricket Ground,24 hrs Room Service, Hi-Class Security.</p>,
  },
  {
    key: '2',
    label: 'Mauli Village is pet-friendly?',
    children: <p>Pets are allowed in the resort premises but you can’t take them in the room.</p>,
  },
  {
    key: '3',
    label: 'Does Mauli village have a pool?',
    children: <p>Yes, Mauli Village have swimming pool.</p>,
  },
  {
    key: '4',
    label: 'Does Mauli Village offer free Wi-Fi?',
    children: <p>Yes, Mauli Village offer free Wi-Fi.</p>,
  },
  {
    key: '5',
    label: 'What is Check-in and check-out time?',
    children: <p>Check-in time is 11.30 AM. Check-out time is 10 AM. You can request the property for early check-in or late check-out by directly reaching out to the property.</p>,
  },
  {
    key: '6',
    label: 'Do you cook food by preferences?',
    children: <p>Yes, we cook Maharashtrian food by preferences.</p>,
  },
];

const Faq = () => {
  return (
    <>
        <div className='container '>

            <div className='row'>
                <div className='col-lg-6 col-12'>
                    <img src={banner2} alt=' Alibaug' />
                </div>
                <div className='col-lg-6 col-12 mt-4 faq'>
                <Collapse accordion items={items} />
                </div>
            </div>
        </div>
    </>
  )
}

export default Faq