import React from 'react'
import gallery17 from "../Images/gallery17.jpg";
import gallery8 from "../Images/gallery8.jpg";
const Acommondation = () => {
  return (
    <>
        <>
  <div className="page-wrapper">
    <section
      className="page-title"
      style={{
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${gallery8})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "100% 100%"
  }}

    >
      <div className="auto-container">
        <div className="text-center">
          <h1 className='md-text'>Accommodation</h1>
        </div>
      </div>
    </section>
    <section className="room-grid-section pb-3 light-bg mx-60 border-shape-top border-shape-bottom">
      <div className="auto-container">
        <div className="title-box text-center">
          <div className="sub-title mb-4">Lorem ipsum is</div>
          <div className="text">
          publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem we share. Official Chef and Owner
            Philippe Massoud superbl.
          </div>
        </div>
        <div className="sortable-masonry">
          <div className="items-container row">
            <div className="col-lg-6 room-block-two masonry-item all cat-1">
              <div className="inner-box">
                <div className="image">
                  <img src={gallery17} alt="" />
                  <div className="text">Rs:.2400/-</div>
                </div>
                <h3>
                  <a href="booking-reservation.html">Double Bed Tent</a>{" "}
                </h3>
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-bed" />2 beds
                    </li>
                    <li>
                      <i className="flaticon-bath-1" />1 Bathroom
                    </li>
                    <li>
                      <i className="flaticon-blueprint" />
                      90 m2
                    </li>
                  </ul>
                </div>
                <div className="text-two">
                placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content

                </div>
                <div className="link-btn">
                  <a
                    href="booking-reservation.html"
                    className="theme-btn btn-style-one btn-md"
                  >
                    <span>room details</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 room-block-two masonry-item all cat-2">
              <div className="inner-box">
                <div className="image">
                  <img src={gallery17} alt="" />
                  <div className="text">Rs:. 4500/-</div>
                </div>
                <h3>
                  <a href="booking-reservation.html">Super Delux Tent</a>
                </h3>
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-bed" />2 beds
                    </li>
                    <li>
                      <i className="flaticon-bath-1" />1 Bathroom
                    </li>
                    <li>
                      <i className="flaticon-blueprint" />
                      90 m2
                    </li>
                  </ul>
                </div>
                <div className="text-two">
                placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content

                </div>
                <div className="link-btn">
                  <a
                    href="booking-reservation.html"
                    className="theme-btn btn-style-one btn-md"
                  >
                    <span>room details</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 room-block-two masonry-item all cat-3">
              <div className="inner-box">
                <div className="image">
                  <img src={gallery17} alt="" />
                  <div className="text">Rs:. 16000/-</div>
                </div>
                <h3>
                  <a href="booking-reservation.html">Sixteen Bed Tent</a>
                </h3>
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-bed" />2 beds
                    </li>
                    <li>
                      <i className="flaticon-bath-1" />1 Bathroom
                    </li>
                    <li>
                      <i className="flaticon-blueprint" />
                      90 m2
                    </li>
                  </ul>
                </div>
                <div className="text-two">
                placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content

                </div>
                <div className="link-btn">
                  <a
                    href="booking-reservation.html"
                    className="theme-btn btn-style-one btn-md"
                  >
                    <span>room details</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 room-block-two masonry-item all cat-4">
              <div className="inner-box">
                <div className="image">
                  <img src={gallery17} alt="" />
                  <div className="text">Rs:. 600/-</div>
                </div>
                <h3>
                  <a href="booking-reservation.html">Extra Bed</a>
                </h3>
                <div className="icon-list">
                  <ul>
                    <li>
                      <i className="flaticon-bed" />2 beds
                    </li>
                    <li>
                      <i className="flaticon-bath-1" />1 Bathroom
                    </li>
                    <li>
                      <i className="flaticon-blueprint" />
                      90 m2
                    </li>
                  </ul>
                </div>
                <div className="text-two">
                placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content

                </div>
                <div className="link-btn">
                  <a
                    href="booking-reservation.html"
                    className="theme-btn btn-style-one btn-md"
                  >
                    <span>room details</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
   
  </div>
  <div className="scroll-to-top scroll-to-target" data-target="html">
    <span className="fas fa-arrow-up" />
  </div>
</>

    </>
  )
}

export default Acommondation