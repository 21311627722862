import React, { useState,useLayoutEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import gallery16 from "../Images/gallery16.jpg";
import mauli1 from "../Images/Hammocks-1.jpg";
import maul2 from "../Images/Hammocks-6.jpg";
import mauli3 from "../Images/Maulivillage-Building-3.jpg";
import maul4 from "../Images/Outdoors-Lawn-Garden-Hammocks-2.jpg";
import mauli5 from "../Images/River-View-Outdoors-5.jpg";
import mauli6 from "../Images/River-View-Outdoors-6-1.jpg";
import mauli7 from "../Images/Swimming-Pool-1.jpg";
import mauli8 from "../Images/Swimming-Pool-2.webp";
import mauli9 from "../Images/Swimming-Pool-3.webp";
import mauli10 from "../Images/Treehouse-Ext-10-1.jpg";
import mauli11 from "../Images/chair.jpg";
import mauli12 from "../Images/mauli-banner-1.webp";
import mauli13 from "../Images/mauli-banner-2.webp";
import mauli14 from "../Images/mauli-banner-3.webp";
import mauli15 from "../Images/mauli-banner-3.webp";
import mauli16 from "../Images/mauli-banner-about-us.webp";
import s1 from "../Images/Standard-Rooms-1.jpg";
import s2 from "../Images/Standard Rooms-2.jpg";
import s3 from "../Images/Standard Rooms-3.jpg";
import s4 from "../Images/Standard Rooms-4.jpg";
import s5 from "../Images/Standard Rooms-5.jpg";
import s6 from "../Images/Standard Rooms-6.jpg";
import s7 from "../Images/Standard Rooms-7.jpg";
import s8 from "../Images/Standard Rooms-8.jpg";

import tree1 from "../Images/Tree-House-1.webp";
import tree2 from "../Images/Tree-House-2.webp";
import tree3 from "../Images/Tree-House-3.webp";
import tree4 from "../Images/Tree-House-4.webp";
import tree5 from "../Images/Tree-House-5.webp";
import tree6 from "../Images/Tree-House-6.webp";
import tree7 from "../Images/Tree-House-7.webp";
import tree8 from "../Images/Tree-House-8.webp";
import tree9 from "../Images/Tree-House-9.webp";

import out1 from "../Images/OUTDOORS-1.jpg";
import out2 from "../Images/OUTDOORS-2.jpg";
import out3 from "../Images/OUTDOORS-3.jpg";
import out4 from "../Images/OUTDOORS-4.jpg";
import out5 from "../Images/OUTDOORS-5.webp";
import out6 from "../Images/OUTDOORS-6.jpg";
import out7 from "../Images/OUTDOORS-7.jpg";
import out8 from "../Images/OUTDOORS-8.jpg";
import out9 from "../Images/OUTDOORS-9.jpg";
import out10 from "../Images/OUTDOORS-10.jpg";
import out11 from "../Images/OUTDOORS-11.jpg";
import out12 from "../Images/OUTDOORS-12.jpg";
import out13 from "../Images/OUTDOORS-13.jpg";
import out14 from "../Images/OUTDOORS-14.jpg";
import out15 from "../Images/OUTDOORS-15.jpg";
import OutDoor from './OutDoor';
import TreeHouse from './TreeHouse';
import TabStandard from './TabStandard';

const { TabPane } = Tabs;

const TabsGallery = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
      });
        // ========== Smile Gallery ========
      const [lightboxOpen, setLightboxOpen] = useState(false);
      const [photoIndex, setPhotoIndex] = useState(0);
      const standardRoomsArray = [
        s1,
        s2,
        s3,
        s4,
        s5,
        s6,
        s7,
        s8
      ];
      const treeHouseArray = [
        tree1,
        tree2,
        tree3,
        tree4,
        tree5,
        tree6,
        tree7,
        tree8,
        tree9
      ];
      const outdoorsArray = [
        out1,
        out2,
        out3,
        out4,
        out5,
        out6,
        out7,
        out8,
        out9,
        out10,
        out11,
        out12,
        out13,
        out14,
        out15
      ];
    
      const images = [
        mauli1,
      maul2,
      mauli3,
      maul4,
      mauli5,
      mauli6,
      mauli7,
      mauli8,
      mauli9,
      mauli10,
      mauli11,
      mauli12,
      mauli13,
      mauli14,
      mauli15,
      mauli16,
    ];
    
      const openLightbox = (index) => {
        setPhotoIndex(index);
        setLightboxOpen(true);
      };
    
      const closeLightbox = () => {
        setLightboxOpen(false);
      };

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
   
    {
        key: '3',
        label: 'Out Door',
        content: <OutDoor />
      },
  ];

  return (
    <Tabs defaultActiveKey="1" centered onChange={onChange}>
    
      {items.map((item) => (
        <TabPane tab={item.label} key={item.key}>
          {item.content}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default TabsGallery;
