import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../Css/Navbar.css";
import logo from "../Images/sonaOne.jpeg";
import mauliLogo from "../Images/mauli-logo.png";
const Navbar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');

  const handleLinkClick = (link) => {
    setSelectedLink(link);
  };

  const isActive = (link) => {
    return link === selectedLink ? 'active' : '';
  };

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  const closeNavbar = () => {
    setNavbarOpen(false);
  };
  return (
    <>
        <header className="main-header header-style-one">
      <div className="">
        <div className="auto-container">
          <div className="inner-container">
          <section>
      <nav className="navbar navbar-expand-lg navbar-light bg-light text-black">
        <div className="container-fluid">
          <div className="logo-box">
            <div className="logo">
              <Link to='/'>
              <a href="/">
                <img src={mauliLogo} alt="" style={{ height: "60px" }} />
              </a>
              </Link>
            </div>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
            <ul className="navbar-nav navigation text-center me-auto mb-2 mb-lg-0" style={{ margin: "auto" }}>
            <li className="nav-item" onClick={() => handleLinkClick('Home')}>
                        <Link to='/' onClick={closeNavbar}  className={`nav-link ${isActive('Home')}`} style={{ textDecoration: "none" }} aria-current="page">Home</Link>
                      </li>
              <li className="nav-item" onClick={() => handleLinkClick('About')}>
                        <Link to='/family-resorts-alibaug' onClick={closeNavbar} className={`nav-link ${isActive('About')}`} style={{ textDecoration: "none" }}>About Us</Link>
                </li>
                <li className="nav-item" onClick={() => handleLinkClick('Packages')}>
                        <Link to='/budget-friendly-resorts-alibaug' onClick={closeNavbar} className={`nav-link ${isActive('Packages')}`} style={{ textDecoration: "none" }}>Packages </Link>
                </li>
                <li className="nav-item" onClick={() => handleLinkClick('Gallery')}>
                        <Link to='/best-resorts-private-pool-alibaug' onClick={closeNavbar} className={`nav-link ${isActive('Gallery')}`} style={{ textDecoration: "none" }}>Gallery </Link>
                </li>
                <li className="nav-item" onClick={() => handleLinkClick('Cuisine')}>
                        <Link to='/best-alibaug-resorts-food' onClick={closeNavbar} className={`nav-link ${isActive('Cuisine')}`} style={{ textDecoration: "none" }}>cuisine</Link>
                </li>
                
                <li className="nav-item" onClick={() => handleLinkClick('Contact')}>
                        <Link to='/best-resorts-near-alibaug' onClick={closeNavbar} className={`nav-link ${isActive('Contact')}`} style={{ textDecoration: "none" }}>Contact</Link>
                </li>
                <li className="nav-item" onClick={() => handleLinkClick('Coming')}>
                        <Link to='/cooming-soon' onClick={closeNavbar} className={`nav-link ${isActive('Coming')}`} style={{ textDecoration: "none" }}>Coming Soon</Link>
                </li>
            </ul>
            <form className="d-flex">
              <div className="right-column">
                <div className="navbar-right">
                  <div className="link-btn">
                    <Link to="/best-resorts-near-alibaug">
                      <a href="" className="theme-btn btn-style-one" onClick={closeNavbar}>Enquiry Now</a>
                    </Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </nav>
    </section>
          </div>
        </div>
        
      </div>
    </header>
    </>
  )
}

export default Navbar