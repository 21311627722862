import React ,{useState} from 'react'
import { Pagination, Autoplay,Navigation } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

import s1 from "../Images/Standard-Rooms-1.webp";
import s3 from "../Images/Standard Rooms-3.webp";
import s4 from "../Images/Standard Rooms-4.webp";
import s5 from "../Images/Standard Rooms-5.webp";
import s6 from "../Images/Standard Rooms-6.webp";
import s8 from "../Images/Standard Rooms-8.webp";



import { Link } from 'react-router-dom';
const StandardRoomPackages = () => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);

  const images = [s1, s3, s4,s5,s6,s6,s8]; // Add more image paths if needed

  const openLightbox = (index) => {
    setImageIndex(index);
    setLightboxIsOpen(true);
  };
  return (
    <>
        <>
  <div className="page-wrapper">
    <section className="room-list-section light-bg mx-60 border-shape-top">
      <div className="auto-container">
        <div className="room-block-three">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="image">
                <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[ Autoplay, Pagination ,Navigation]}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} onClick={() => openLightbox(index)}>
            <img src={image} alt="" />
          </SwiperSlide>
        ))}
                </Swiper>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxIsOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)
          }
        />
      )}
              </div>
            </div>
            <div className="col-lg-6 standard-package-tile">
              <h3 className='package-title'>
                 Standard Package : With Two Meals
              </h3>
              <p className='d-none'> Budget Friendly Resorts in Alibaug </p>
              <div className="icon-list">
                <ul>
                  <li >
                    <label>Double Sharing :</label> <br/><label className='fw-bold'>₹2600/Person</label>
                  </li>
                  <li >
                    <label>Triple Sharing :</label> <br/> <label className='fw-bold'> ₹2400/Person</label> 
                  </li>
                  <li >
                    <label>Four Sharing :</label> <br/><label className='fw-bold'> ₹2200/Person</label> 
                  </li>
                </ul>
              </div>
              <div className="text-two">
              <div className='row'>
                    <div className=' fw-bold'>
                     Accommodation Facilities:
                    </div>
                    <div className='row'>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Free WiFi</li>
                      <li class="">King Size bed in each rooms</li>
                      <li class="">LED Smart TV with cable connections</li>
                    </ul>
                    </div>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Iron(on request)</li>
                      <li class="">Attached Bathroom with Toiletries</li>
                    </ul>
                    </div>

                    </div>
                </div>
                <div className='include row'>
                <div className='row col-lg-6'>
                    <div className='col-lg6 fw-bold'>
                        Include:
                    </div>
                    <div className='col-lg6'>
                    <div>
                    Two 1 Ltr Water Bottles in the Room
                    </div>
                    <div>
                    One Breakfast, Two Meals, Two times Tea/Coffee,
                    </div>
                    <p>  Check In: 12:30 pm </p>
                    <p>Check Out: 10:00 am</p>
                    </div>
                </div>
                <div className="link-btn book-nows col-lg-6">
                <Link to='/best-resorts-near-alibaug'>
                <a
                  href="booking-reservation.html"
                  className="theme-btn btn-style-one btn-md"
                >
                  <span>enquiry now</span>
                </a>
                </Link>
                <p className='d-none'> Budget Friendly Resorts in Alibaug </p>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      
        <div className="room-block-three ">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="image">
                <Swiper
                spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[ Autoplay, Pagination ,Navigation]}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} onClick={() => openLightbox(index)}>
            <img src={image} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxIsOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)
          }
        />
      )}
              </div>
            </div>
            <div className="col-lg-6 standard-package-tile">
              <h3 className='package-title'>
                 Standard Package : One Meal
              </h3>
              <div className="icon-list">
                <ul>
                  <li >
                    <label>Double Sharing :</label> <br/><label className='fw-bold'>₹2200/Person</label>
                  </li>
                  <li >
                    <label>Triple Sharing :</label> <br/> <label className='fw-bold'> ₹2000/Person</label> 
                  </li>
                  <li >
                    <label>Four Sharing :</label> <br/><label className='fw-bold'> ₹1800/Person</label> 
                  </li>
                </ul>
              </div>
              <div className="text-two">
              <div className='row'>
                    <div className=' fw-bold'>
                     Accommodation Facilities:
                    </div>
                    <div className='row'>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Free WiFi</li>
                      <li class="">King Size bed in each rooms</li>
                      <li class="">LED Smart TV with cable connections</li>
                    </ul>
                    </div>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Iron(on request)</li>
                      <li class="">Attached Bathroom with Toiletries</li>
                    </ul>
                    </div>

                    </div>
                </div>
                <div className='row '>
                <div className='row col-lg-6'>
                    <div className='fw-bold'>
                        Include:
                    </div>
                    <div>
                    Two 1 Ltr Water Bottles in the Room
                    </div>
                    <div className=''>
                    One Breakfast, One Meal, Two times Tea/Coffee
                    </div>
                    <p>  Check In: 12:30 pm </p>
                    <p>Check Out: 10:00 am</p>
                </div>
                <div className="link-btn book-nows col-lg-6">
                <Link to='/best-resorts-near-alibaug'>
                <a
                  href="booking-reservation.html"
                  className="theme-btn btn-style-one btn-md"
                >
                  <span>enquiry now</span>
                </a>
                </Link>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className="room-block-three">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="image">
                <Swiper
                spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[ Autoplay, Pagination , Navigation]}
        className="mySwiper"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} onClick={() => openLightbox(index)}>
            <img src={image} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>

      {lightboxIsOpen && (
        <Lightbox
          mainSrc={images[imageIndex]}
          nextSrc={images[(imageIndex + 1) % images.length]}
          prevSrc={images[(imageIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxIsOpen(false)}
          onMovePrevRequest={() =>
            setImageIndex((imageIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setImageIndex((imageIndex + 1) % images.length)
          }
        />
      )}
              </div>
            </div>
            <div className="col-lg-6 standard-package-tile">
              <h3 className='package-title'>
                 Basic Package : No Meals
              </h3>
              <div className="icon-list">
                <ul>
                  <li >
                    <label>Double Sharing :</label> <br/><label className='fw-bold'>₹1750/Person</label>
                  </li>
                  <li >
                    <label>Triple Sharing :</label> <br/> <label className='fw-bold'> ₹1550/Person</label> 
                  </li>
                  <li >
                    <label>Four Sharing :</label> <br/><label className='fw-bold'> ₹1350/Person</label> 
                  </li>
                </ul>
              </div>
              <div className="text-two">
              <div className='row'>
                    <div className=' fw-bold'>
                     Accommodation Facilities:
                    </div>
                    <div className='row'>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                    
                    <li class="">Free WiFi</li>
                      <li class="">King Size bed in each rooms</li>
                      <li class="">LED Smart TV with cable connections</li>
                    </ul>
                    </div>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Iron(on request)</li>
                      <li class="">Attached Bathroom with Toiletries</li>
                    </ul>
                    </div>

                    </div>
                </div>
                <div className='row '>
                <div className='row col-lg-6'>
                    <div className='col-lg6 fw-bold'>
                        Include:
                    </div>
                    <div className='col-lg6'>
                    <div>
                    Two 1 Ltr Water Bottles in the Room
                    </div>
                   <div>
                   One Breakfast, Two times Tea/Coffee
                   </div>
                   <p>  Check In: 12:30 pm </p>
                    <p>Check Out: 10:00 am</p>
                    </div>
                </div>
                <div className="link-btn book-nows col-lg-6">
                <Link to='/best-resorts-near-alibaug'>
                <a
                  href="booking-reservation.html"
                  className="theme-btn btn-style-one btn-md"
                >
                  <span>enquiry now</span>
                </a>
                </Link>
              </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>
    
  </div>
  <div className="scroll-to-top scroll-to-target" data-target="html">
    <span className="fas fa-arrow-up" />
  </div>
</>

    </>
  )
}

export default StandardRoomPackages