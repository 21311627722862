import React from 'react'
import MetaTags from 'react-meta-tags';
import treeHouse from "../Images/mauli-banner-3.webp";
import "../Css/Home.css";
import { FaPersonSwimming } from "react-icons/fa6";
import { SiFireship } from "react-icons/si";
import { CgGames } from "react-icons/cg";
import { PiChairBold } from "react-icons/pi";
import { MdOutlineAirlineSeatReclineExtra } from "react-icons/md";

import site5 from "../Images/sightseeing5.webp";

import { FaWifi } from "react-icons/fa6";
import { GiCctvCamera } from "react-icons/gi";
import { IoIosCall } from "react-icons/io";
import { LuParkingCircle } from "react-icons/lu";
import { FaCarSide } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import hammock from "../Images/hammock.png";

import mauliAboutUs from "../Images/mauli-banner-about-us.webp";
import Slider from './Slider';
import PackageSlider from './PackageSlider';
import { Link } from 'react-router-dom';
import TestimonialSlider from '../Pages/TestimonialSlider';
import Faq from '../Pages/Faq';
const Home = () => {
  return (
    <>
        <>
        <MetaTags>
      <title>Top Resorts in Alibaug | Mauli Village Resort</title>
        <meta title="Top Resorts in Alibaug | Mauli Village Resort" />
        <meta
          name="description"
          content="Indulge in luxury and tranquility at the Top Resorts in Alibaug. Escape the hustle, unwind in scenic beauty, and create lasting memories on your perfect getaway. "
        />
        <meta
          name="keywords"
          content="Top Resorts in Alibaug, Resorts For Family in Alibaug,Budget-Friendly Resorts in Alibaug,Best Resorts with Private Pool in Alibaug,Best Resorts in Alibaug with Food,Best Resorts Near Alibaug"
        />
        <link rel="canonical" href="https://maulivillage.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Top Resorts in Alibaug | Mauli Village Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maulivillage.com/"/>
        <meta property="og:description" content="Indulge in luxury and tranquility at the Top Resorts in Alibaug. Escape the hustle, unwind in scenic beauty, and create lasting memories on your perfect getaway. " />
        <meta property="og:image" content="https://maulivillage.com/" />       
      </MetaTags>
  <div className="page-wrapper">
    <div id="search-popup" className="search-popup">
      <div className="close-search theme-btn">
        <i className="far fa-times" />
      </div>
      <div className="popup-inner">
        <div className="overlay-layer" />
        <div className="search-form">
          <form method="post" action="index.html">
            <div className="form-group">
              <fieldset>
                <input
                  type="search"
                  className="form-control"
                  name="search-input"
                  defaultValue=""
                  placeholder="Search Here"
                  required=""
                />
                <input
                  type="submit"
                  defaultValue="Search Now!"
                  className="theme-btn"
                />
              </fieldset>
            </div>
          </form>
        </div>
      </div>
    </div>

    <section className="banner-section">
  <div className="swiper-container banner-slider">
    <div className="swiper-wrapper">
      <Slider />
    </div>
  </div>
</section>

    
  

    <section className="about-us-section light-bg mx-60  border-shape-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="content-block">
              <div className="title-box">
                <div className="sub-title">about us</div>
                <h1 className="sec-title">
                Top Resorts in Alibaug
                </h1>
              </div>
              <div className="text">
              
              Welcome to "Mauli Village," a place that beckons you back tirelessly, where you can relish the ultimate escape. Discover the tranquility and splendor that make it one of the Top Resorts in Alibaug, calling you to return again and again. Escape to Mauli Village—a haven of peace and rejuvenation!
              <br/>
              Step into this serene sanctuary where you can rejuvenate your connections with family and friends—an idyllic retreat that feels like a second home. Embrace the essence of being reborn amidst the embrace of Mother Nature's warmth and comforts.
              <br/>
              Explore lush surroundings adorned with mango trees, coconut groves, and chikku plantations. Forge new friendships amidst the company of swans, rabbits, ducks, fowls, turkeys, and more. As the monsoon season arrives, let the melodic tunes of rivulets and the rhythmic sounds of waterfalls wash away the daily stresses of life. Unwind on hammocks, bask in the sun, and listen to the soothing rustle of the wind.
              
              </div>
             
           
              <div className="link-btn shadow-lg">
                <Link to="/best-resorts-private-pool-alibaug">
                <a href="#" className="theme-btn btn-style-two btn-lg">
                  <span>
                    discover more <i className="flaticon-right-arrow" />
                  </span> 
                </a>
                </Link>
              </div>
             
            </div>
          </div>
          <div className="col-lg-6 invite-img">
            <div className="image img_hover_3">
              <img src={mauliAboutUs} alt="Top Resorts" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="cta-section">
      <div
        className="bg-image"
        data-parallax='{"x": 40}'
        style={{ 
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${treeHouse})`,
          }}
      />
      <div className="offer-image">
        <img src="assets/images/resource/image-8.png" alt=" Resorts in Alibaug" />
      </div>
      <div className="auto-container">
        <div className="row">
          <div className="col-xl-5 offset-xl-7 cta-block">
            <div className="title-box text-light">
              <div className="sub-title">Canopy Haven</div>
              <h2 className="sec-title text-white">Experiencing Luxury in Our Treehouse Oasis</h2>
            </div>
            <div className="text">
            Experience unmatched luxury as you retreat to our treehouse sanctuary, a refuge where elegance intertwines with the embrace of nature. Discover the epitome of opulence at the top resorts in Alibaug.

            </div>
            <div className="link-btn">
              <Link to='/best-resorts-private-pool-alibaug'>
              <a href="#" className="theme-btn btn-style-one">
                <span>find out more</span>
              </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="amenities-section light-bg border-shape-bottom">
        <div class="container">
            <div class="title-box text-center">
                <div class="sub-title">amenities</div>
                <h2 class="sec-title">Make Your Stay Memorable</h2>
            </div>

            <div class="row ">
                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2">
                        <IoIosCall />

                        </div>
                        <h2 className='amenities-text'>24/7 Service</h2>
                  
                    </div>                    
                </div>

                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2">
                        <FaWifi />
                        </div>
                        <h2 className='amenities-text'>Free WiFi Access</h2>
                    </div>
                </div>

                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2"><i class="flaticon-smart-tv"></i>
                        <GiCctvCamera />
                        </div>
                        <h2 className='amenities-text'>High-Class Security</h2>
                    </div>
                </div>
                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2"><i class="flaticon-pool"></i>
                        <FaCarSide />

                        </div>
                        <h2 className='amenities-text'>Parking</h2>
                       
                    </div>
                </div>
                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2"><i class="flaticon-pool"></i>
                        <FaUsers />
                        </div>
                        <h2 className='amenities-text'>Conference Hall</h2>
                       
                    </div>
                </div>

            </div>
            <div class="row ">
                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2">
                        <FaPersonSwimming />


                        </div>
                        <h2 className='amenities-text'>Swimming Pool</h2>
                  
                    </div>                    
                </div>

                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2">
                        <SiFireship />

                        </div>
                        <h2 className='amenities-text'>Bonfire</h2>
                    </div>
                </div>

                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2"><i class="flaticon-smart-tv"></i>
                        <CgGames />

                        </div>
                        <h2 className='amenities-text'>Games</h2>
                    </div>
                </div>
                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2"><i class="flaticon-pool"></i>
                        <PiChairBold />
                        </div>
                        <h2 className='amenities-text'>Garden Seating Arrangement</h2>
                       
                    </div>
                </div>
                <div class="col-lg amenities-block text-center">
                    <div class="inner-box ami-con">
                        <div class="icon mb-2"><i class="flaticon-pool"></i>
                        <MdOutlineAirlineSeatReclineExtra />

                        </div>
                        <h2 className='amenities-text'>Lawn With Hammocks</h2>
                       
                    </div>
                </div>

            </div>
            
        </div>
    </section>
  
    <section className="room-section">
      <div className="container">
        <div className="top-content">
          <div className="title-box text-light">
            <div className="sub-title"> Exploring landmarks</div>
            <h2 className="sec-title">Sightseeing</h2>
          </div>
        </div>
  
        <PackageSlider />
        
        
      </div>
    </section>
   
   
    <section className="room-section">
      <div className="container">
        <div className="top-content">
          <div className="title-box text-light">
            <div className="sub-title"> FAQ</div>
            <h2 className="sec-title">Frequently Asked Questions</h2>
          </div>
        </div>
        <Faq />
      </div>
    </section>
    <section className="testimonials-section light-bg mx-60 border-shape-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div
              className="content-block"
              style={{
                backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${site5})`,
              }}
            >
              <div className="title-box text-light">
                <div className="sub-title">Testimonials</div>
                <h2 className="sec-title text-white">Guest's Review</h2>
              </div>
              
            </div>
          </div>
          <div className="col-lg-5 testimonial-slider">
            <div
              className=""
            > <TestimonialSlider />
              
            </div>
          </div>
        </div>
      </div>
    </section>
   
    
  </div>
  <div className="scroll-to-top scroll-to-target" data-target="html">
    <span className="fas fa-arrow-up" />
  </div>
</>

    </>
  )
}

export default Home