import React,{useLayoutEffect} from 'react'
import MetaTags from 'react-meta-tags';

import fire from "../Images/fire.jpeg";
import chooseUs from "../Images/fire.jpeg";
import NumberCounter from 'number-counter';
import gallery17 from "../Images/gallery17.jpg";
import { Link } from 'react-router-dom';
import TabsPackage from '../Pages/TabsPackage';
import banner1 from "../Images/mauli-banner-about-us.webp";
const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
    <>
    <MetaTags>
      <title>Budget-Friendly Resorts in Alibaug | Mauli Village Resort</title>
        <meta title="Budget-Friendly Resorts in Alibaug | Mauli Village Resort" />
        <meta
          name="description"
          content="Unwind without breaking the bank! Explore our curated list of Budget-Friendly Resorts in Alibaug, offering a perfect blend of relaxation and affordability.  "
        />
        <meta
          name="keywords"
          content="Top Resorts in Alibaug, Resorts For Family in Alibaug,Budget-Friendly Resorts in Alibaug,Best Resorts with Private Pool in Alibaug,Best Resorts in Alibaug with Food,Best Resorts Near Alibaug"
        />
        <link rel="canonical" href="https://maulivillage.com/budget-friendly-resorts-alibaug" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Budget-Friendly Resorts in Alibaug | Mauli Village Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maulivillage.com/budget-friendly-resorts-alibaug"/>
        <meta property="og:description" content="Unwind without breaking the bank! Explore our curated list of Budget-Friendly Resorts in Alibaug, offering a perfect blend of relaxation and affordability.  " />
        <meta property="og:image" content="https://maulivillage.com/" />       
      </MetaTags>
      <p className='seo-para'>
          Discover Mauli Resort, a haven of tranquility that stands as one of the premier Budget-Friendly Resorts in Alibaug. Nestled amidst scenic landscapes, our resort offers an exceptional blend of affordability and excellence, catering to those who seek a memorable stay without breaking the bank.

        At Mauli Resort, we prioritize your comfort and budget, ensuring that every aspect of your stay reflects our commitment to being one of the best Budget-Friendly Resorts in Alibaug. Our serene ambiance, cozy accommodations, and delectable cuisine create an inviting retreat for those who appreciate quality within budget constraints.

        As you step into Mauli Resort, the budget-friendly charm seamlessly intertwines with the lush surroundings, providing a harmonious escape from the hustle and bustle of everyday life. Our thoughtfully designed rooms offer a tranquil space where nature meets affordability, allowing you to unwind and rejuvenate.

        Repeatedly acknowledged as one of the top Budget-Friendly Resorts in Alibaug, Mauli Resort emphasizes creating an accessible and memorable experience for all guests. From the inviting atmosphere to the delightful amenities, every detail is crafted to ensure a stay that not only fits your budget but exceeds your expectations. Embrace affordability without compromise at Mauli Resort, where your Alibaug getaway becomes an enriching and economical retreat.
      </p>
  <div className="page-wrapper">
    <section
      className="page-title"
      style={{
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${banner1})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "100% 100%"
  }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Budget Friendly Resorts in Alibaug</h1>
        </div>
      </div>
    </section>
    
    <section>
    <section className="news-section pt-0 light-bg mx-60 border-shape-bottom pt-4">
  <div className="container">
    <div className="title-box text-center">
      <div className="sub-title">Packages </div>
      <p className='d-none'> Budget Friendly Resorts in Alibaug </p>
      <h2 className="sec-title"> Our Package</h2>
    </div>
    <section>
      <TabsPackage />
    </section>
  
  </div>
</section>
    </section>
  </div>
  <div className="scroll-to-top scroll-to-target" data-target="html">
    <span className="fas fa-arrow-up" />
  </div>
</>

    </>
  )
}

export default About