import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import food1 from "../Images/food/mauliFood(1).webp";
import food2 from "../Images/food/mauliFood(3).webp";
import food3 from "../Images/food/mauliFood(4).webp";
import food4 from "../Images/food/mauliFood(5).webp";
import food5 from "../Images/food/mauliFood(6).webp";
import food6 from "../Images/food/mauliFood(7).webp";
import food7 from "../Images/food/mauliFood(8).webp";

const FoodSlider = () => {
  const foodData = [
    { title: 'Food 1', image: food1 , alternative: " Alibaug with Food" },
    { title: 'Food 2', image: food2 , alternative: "Best Resorts in Alibaug " },
    { title: 'Food 3', image: food3 , alternative: "Best Resorts in Alibaug with Food" },
    { title: 'Food 4', image: food4 , alternative: " Alibaug with Food" },
    { title: 'Food 5', image: food5 , alternative: "Best Resorts in Alibaug " },
    { title: 'Food 6', image: food6 , alternative: " Alibaug with Food" },
    { title: 'Food 7', image: food7 , alternative: "Best Resorts  with Food" },
  ];

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {foodData.map((food, index) => (
          <SwiperSlide key={index}>
            <div className='news-block'>
              <div className="inner-box text-center cuisine-slider">
                <div className="image">
                  <a href="#">
                    <img src={food.image} className='food-img img-fluid' alt={` ${food.alternative}`} style={{height:"100% !important"}} />
                  </a>
                </div>
                <div className="title mt-2 mb-2">
                  <p>{food.title}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default FoodSlider;
