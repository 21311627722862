import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import sightseeing1 from "../Images/sightseeing1.webp";
import sightseeing2 from "../Images/sightseeing2.webp";
import sightseeing3 from "../Images/sightseeing3.webp";
import sightseeing4 from "../Images/sightseeing4.webp";
import sightseeing5 from "../Images/sightseeing5.webp";
import sightseeing6 from "../Images/sightseeing6.webp";
import '../Css/PackageSlider.css'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Autoplay,Pagination } from 'swiper/modules';

export default function PackageSlider() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          '@0.00': {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          '@0.75': {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          '@1.00': {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          '@1.50': {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide> 
        <div className='news-block'>
  <div className="inner-box">
    <div className="image">
      <a href="#">
        <img src={sightseeing1} alt=" Resorts in Alibaug" />
      </a>
    </div>
    <div className=" scroll4">
      <div className="hidden-content">
        <h3>
          <a href="#">Kihim Beach</a>
        </h3>
        <div className="post-meta">
        Kihim Beach emerges as a well-known spot in Alibaug, celebrated for its widespread appeal. Positioned away from the vibrant city center, it provides a more peaceful ambiance in contrast to Alibaug Beach. Strategically positioned around 3 kilometers from Mauli Village, it presents a tranquil retreat away from the hustle and bustle, making it an ideal location for top resorts in Alibaug.
        </div>
      </div>
    </div>
  </div>
</div>
            </SwiperSlide>
        
        <SwiperSlide>
        <div className='news-block'>
  <div className="inner-box">
    <div className="image">
      <a href="#">
        <img src={sightseeing2} alt="Top Resorts" />
      </a>
    </div>
    <div className=" scroll4">
      <div className="hidden-content">
        <h3>
          <a href="#">Karmarkar Museum</a>
        </h3>
        <div className="post-meta">
        While you are staying at Mauli Village, you must visit Karmarkar Museum which is located approximately 18 kms from Alibaug town on the Alibaug-Rewas Road. Beautifully curved Sculptors are made by Mr. Karmarkar Which is displayed in an old bungalow.

        </div>
      </div>
    </div>
  </div>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='news-block'>
  <div className="inner-box">
    <div className="image">
      <a href="#">
        <img src={sightseeing3} alt=" Resorts in Alibaug" />
      </a>
    </div>
    <div className=" scroll4">
      <div className="hidden-content">
        <h3>
          <a href="#">Kankeshwar Temple</a>
        </h3>
        <div className="post-meta">
        During your stay at Mauli Village, a must-visit attraction is the Karmarkar Museum, situated around 18 kilometers from Alibaug town on the Alibaug-Rewas Road. Mr. Karmarkar's intricately crafted sculptures adorn an old bungalow, creating a captivating display of artistry.

        </div>
      </div>
    </div>
  </div>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='news-block'>
  <div className="inner-box">
    <div className="image">
      <a href="#">
        <img src={sightseeing4} alt="Top Resorts " />
      </a>
    </div>
    <div className=" scroll4">
      <div className="hidden-content">
        <h3>
          <a href="#">water sports</a>
        </h3>
        <div className="post-meta">
        Experience a range of thrilling water sports, including parasailing, jet skiing, banana boat riding, and more, at Kihim Beach.
        </div>
      </div>
    </div>
  </div>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='news-block'>
  <div className="inner-box">
    <div className="image">
      <a href="#">
        <img src={sightseeing5} alt="Top Resorts " />
      </a>
    </div>
    <div className=" scroll4">
      <div className="hidden-content">
        <h3>
          <a href="#">Mandwa</a>
        </h3>
        <div className="post-meta">
        A well-loved weekend beach retreat from Mumbai city, easily accessible through convenient ferry services operating between Mandwa Beach and Mumbai.
        </div>
      </div>
    </div>
  </div>
</div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='news-block'>
  <div className="inner-box">
    <div className="image">
      <a href="#">
        <img src={sightseeing6} alt=" Resorts in Alibaug" />
      </a>
    </div>
    <div className=" scroll4">
      <div className="hidden-content">
        <h3>
          <a href="#">Alibaug Beach</a>
        </h3>
        <div className="post-meta">
        Alibag is renowned for its pristine beaches, delectable Fish Thali, temples, forts, and a myriad of other appealing attractions. While there are numerous tourist spots in and around the beach, the standout feature is the historic Kolaba Fort, commissioned by the esteemed Chhatrapati Shivaji Maharaj. The sandy shoreline, adorned with neatly aligned coconut trees, adds to the enchanting beauty of this destination.
        </div>
      </div>
    </div>
  </div>
</div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
