import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs } from 'antd';
import { Autoplay, Pagination,Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import StandardRoomPackages from './StandardRoomPackages';
import tree7 from "../Images/Tree-House-7_700x463.webp";
import tree5 from "../Images/Tree-House-5_700x463.webp";
import tree4 from "../Images/Tree-House-4_700x463.webp"
import tree6 from "../Images/Tree-House-6_700x463.webp"
import tree8 from "../Images/Tree-House-8_700x463.webp"


const { TabPane } = Tabs;

const TabsPackage = () => {
  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: '1',
      label: 'Standard Rooms',
      content: <StandardRoomPackages />,
    },
    {
      key: '2',
      label: 'Tree House',
      content: <div>
        <div className="row py-5">
      <div className="col-lg-6 news-block">
        <div className="inner-box">
          <div className="image">
            
              
              <Swiper spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay,  Navigation]}
        className="mySwiper">
        <SwiperSlide><img src={tree7} alt=" Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree5} alt="Budget Friendly " /></SwiperSlide>
        <SwiperSlide><img src={tree4} alt=" Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree6} alt="Budget Friendly " /></SwiperSlide>
        <SwiperSlide><img src={tree8} alt=" Resorts in Alibaug" /></SwiperSlide>

      </Swiper>
            
          </div>
          <div className="lower-content">
            <div className="date">
            ₹4500  <br />
              <span>/ Person</span>
            </div>
            <h3>
              
                {" "}
                 STANDARD PACKAGE : TWO MEALS

              
            </h3>
            
            <div className="post-meta">
                <div className='row'>
                    <div className=' fw-bold'>
                     Accommodation Facilities:
                    </div>
                    <div className='row'>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Smart TV</li>
                      <li class="">24/7 Hot Water</li>
                      <li class="">Mini Fridge</li>
                    </ul>
                    </div>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Iron(on request)</li>
                      <li class="">Tea Kettle</li>
                      <li>Free Wifi</li>
                    </ul>
                    </div>

                    </div>
                </div>
                <ul>
                    <li>Double Sharing</li>
                    <li>Package Include : One Breakfast,</li>
                    <li>Evening Snacks, Two Meals, Two times Tea/Coffee</li>
                    <li> Check In: 12:00pm <br/> Check Out: 10:00 am</li>
                </ul>
            </div>
            <div className="link-btn">
            <Link to='/best-resorts-near-alibaug'>
              <a style={{textDecoration:"none"}}   className="theme-btn btn-style-three">
                <span>ENQUIRY NOW</span>
              </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 news-block">
        <div className="inner-box">
          <div className="image">
              <Swiper spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper">
        <SwiperSlide><img src={tree7} alt=" Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree5} alt="Budget Friendly Resorts " /></SwiperSlide>
        <SwiperSlide><img src={tree4} alt=" Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree6} alt="Budget Friendly " /></SwiperSlide>
        <SwiperSlide><img src={tree8} alt="Budget-Friendly Resorts in Alibaug" /></SwiperSlide>

      </Swiper>
            
          </div>
          <div className="lower-content">
          <div className="date">
          ₹6500  <br />
              <span>/ Person</span>
            </div>
            <h3>
              
                {" "}
                 WEEKEND SPECIAL PACKAGE : THREE MEALS

              
            </h3>
            
            <div className="post-meta">
            <div className='row'>
                    <div className=' fw-bold'>
                     Accommodation Facilities:
                    </div>
                    <div className='row'>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Smart TV</li>
                      <li class="">24/7 Hot Water</li>
                      <li class="">Mini Fridge</li>
                    </ul>
                    </div>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Iron(on request)</li>
                      <li class="">Tea Kettle</li>
                      <li>Free Wifi</li>
                    </ul>
                    </div>

                    </div>
                </div>
                <ul>
                    <li>Double Sharing</li>
                    <li>Package Include : One Breakfast,</li>
                    <li>Evening Snacks, Three Meals, Two times Tea/Coffee</li>
                    <li className='d-block'>Check In: 12:00 pm </li>
                    <li>Check Out: 05:00 pm</li>
                </ul>
            </div>
            <div className="link-btn">
            <Link to='/best-resorts-near-alibaug'>
              <a style={{textDecoration:"none"}}   className="theme-btn btn-style-three">
                <span>ENQUIRY NOW</span>
              </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <div className='row py-5'>
    <div className="col-lg-6 news-block">
        <div className="inner-box">
          <div className="image">
            
              <Swiper spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper">
        <SwiperSlide><img src={tree7} alt=" Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree5} alt="Budget Friendly " /></SwiperSlide>
        <SwiperSlide><img src={tree4} alt=" Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree6} alt="Budget Friendly " /></SwiperSlide>
        <SwiperSlide><img src={tree8} alt=" Resorts in Alibaug" /></SwiperSlide>

      </Swiper>
            
          </div>
          <div className="lower-content">
          <div className="date">
          ₹3500  <br />
              <span>/ Person</span>
            </div>
            <h3>
              
                {" "}
                 STANDARD PACKAGE : ONE MEAL

              
            </h3>
           
            <div className="post-meta">
            <div className='row'>
                    <div className=' fw-bold'>
                     Accommodation Facilities:
                    </div>
                    <div className='row'>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Smart TV</li>
                      <li class="">24/7 Hot Water</li>
                      <li class="">Mini Fridge</li>
                    </ul>
                    </div>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Iron(on request)</li>
                      <li class="">Tea Kettle</li>
                      <li>Free Wifi</li>
                    </ul>
                    </div>

                    </div>
                </div>
                <ul>
                    <li>Double Sharing</li>
                    <li>Package Include : One Breakfast,</li>
                    <li>Evening Snacks, One Meals, Two times Tea/Coffee</li>
                    <li className='d-block'>Check In: 12:00pm </li>  
                    <li> Check Out: 10:00 am</li>
                </ul>
            </div>
            <div className="link-btn">
            <Link to='/best-resorts-near-alibaug'>
              <a style={{textDecoration:"none"}}   className="theme-btn btn-style-three">
                <span>ENQUIRY NOW</span>
              </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 news-block">
        <div className="inner-box">
          <div className="image">
            
              <Swiper spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Navigation]}
        className="mySwiper">
        <SwiperSlide><img src={tree7} alt="Budget Friendly " /></SwiperSlide>
        <SwiperSlide><img src={tree5} alt=" Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree4} alt="Resorts in Alibaug" /></SwiperSlide>
        <SwiperSlide><img src={tree6} alt="Budget Friendly " /></SwiperSlide>
        <SwiperSlide><img src={tree8} alt=" Resorts in Alibaug" /></SwiperSlide>

      </Swiper>
            
          </div>
          <div className="lower-content">
          <div className="date">
          ₹3000  <br />
              <span>/ Person</span>
            </div>
            <h3>
              
                {" "}
                 BASIC PACKAGE : NO MEALS
              
            </h3>
           
            <div className="post-meta">
            <div className='row'>
                    <div className=' fw-bold'>
                     Accommodation Facilities:
                    </div>
                    <div className='row'>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Smart TV</li>
                      <li class="">24/7 Hot Water</li>
                      <li class="">Mini Fridge</li>
                    </ul>
                    </div>
                    <div className='col-6'>
                    <ul class="list-unstyled custom-list-style">
                      <li class="">Iron(on request)</li>
                      <li class="">Tea Kettle</li>
                      <li>Free Wifi</li>
                    </ul>
                    </div>

                    </div>
                </div>
                <ul>
                    <li>Double Sharing</li>
                    <li>Package Include : One Breakfast,</li>
                    <li> Evening Snacks, Two times Tea/Coffee</li>  
                    <li className='d-block'>Check In: 12:00pm </li>
                    <li> Check Out: 10:00 am</li>
                </ul>
            </div>
            <div className="link-btn">
            <Link to='/best-resorts-near-alibaug'>
              <a style={{textDecoration:"none"}}   className="theme-btn btn-style-three">
                <span>ENQUIRY NOW</span>
              </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>,
    },
  ];

  return (
    <Tabs defaultActiveKey="1" centered onChange={onChange}>
    
      {items.map((item) => (
        <TabPane tab={item.label} key={item.key}>
          {item.content}
        </TabPane>
      ))}
    </Tabs>
  );
};

export default TabsPackage;
