// import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
// import { Navbar } from 'react-bootstrap';
import Navbar from "./Components/Navbar";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Packages from './Components/Packages';
import Gallery from './Components/Gallery';
import Footer from './Components/Footer';
import About from './Pages/About';
import Contact from './Components/Contact';
import Acommondation from './Components/Acommondation';
import SightSeeing from './Pages/SightSeeing';
import Cuisine from './Pages/Cuisine';
import CoomingSoon from './Pages/CoomingSoon';
import Whatapp from './Components/Whatapp';
import ErrorPage from './Pages/ErrorPage';
import FloatingIcon from './Pages/FloatingIcon';
function App() {
  return (
    <>
    <BrowserRouter>
    <FloatingIcon />
    {/* <whatapp  */}
    {/* <Whatapp/> */}
    <Navbar />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/budget-friendly-resorts-alibaug' element={<Packages />} />
      <Route path='/best-resorts-private-pool-alibaug' element={<Gallery />} />
      <Route path='/family-resorts-alibaug' element={<About />} />
      <Route path='/best-resorts-near-alibaug' element={<Contact/>}/>
      <Route path='/accommodation' element={<Acommondation />}/>
      <Route path='/cooming-soon' element={<CoomingSoon/>}/>
      <Route path='/site' element={<SightSeeing />} />
      <Route path='/best-alibaug-resorts-food' element={<Cuisine/>}/>
      <Route path='/*' element={<ErrorPage />} />
    </Routes>
    <Footer />
    </BrowserRouter>
    
    </>
  );
}

export default App;
