import React from 'react'
import logo from "../Images/mauli-logo.png";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <>
        <footer className="main-footer ">
      <div className="auto-container footer">
        <div className="widgets-section ">
          <div className="row clearfix">
            <div className="column col-lg-3 ">
              <div className="">
                <div className="image">
                  <a href="#">
                    <img src={logo} style={{height:"80px"}} alt="" />
                  </a>
                </div>
                <br />
                <div className="widget-content">
                  <ul>
                    <li>
                      <p className='logo-para text-black' >
                      Make every moment glorious. Let yourself be tempted by the magnificent landscapes and warm climate of Alibag


                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="column col-lg-2">
              <div className="widget links-widget">
                <h3 className="widget-title text-black">Quick Links</h3>
                <div className="widget-content ">
                  <ul>
                    
                    <li  className='text-black'>
                      <Link to='/family-resorts-alibaug'>
                      <a href="#">About us</a>
                      </Link>
                      {/* <hr style={{color: "var(--theme-color);"}}/> */}
                    </li>
                    <li>
                      <Link to='/budget-friendly-resorts-alibaug'>
                      <a href="/">Package</a>
                      </Link>
                    </li>
                    <li>
                      <Link to='/best-resorts-private-pool-alibaug'>
                      <a href="/">Gallery</a>
                      </Link>
                    </li>
                    <li>
                      <Link to='/best-alibaug-resorts-food'>
                      <a href="/">Cuisine</a>
                      </Link>
                    </li>
                    <li>
                      <Link to='/best-resorts-near-alibaug'>
                      <a href="/">Conatct</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/*Column*/}
            <div className="column col-lg-4 text-black">
              <div className="widget contact-widget">
              <h3 className="widget-title text-black">Get In Touch</h3>
                <table>
                  <tbody>
                    <tr>
                      <td className="lebel"> Tel :</td>
                      <td>
                        <div><a href="tel:+91 7798414910">+91 77984 14910</a></div> 
                        <div><a href="tel:+91 9503891779">+91 9503891779</a></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="lebel">Email :</td>
                      <td>
                        <a href="mailto:maulivillage@gmail.com">maulivillage@gmail.com</a>
                      </td>
                    </tr>
                    <tr>
                      <td className="lebel">Location:</td>
                      <td>
                      At - Satirje , Post - Mapagaon <br/> Tal - Alibaug , Dist - Raigad <br/> Maharashtra - 402201
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/*Column*/}
            <div className="column col-lg-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15113.741411061726!2d72.88789!3d18.7340812!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be87824c787353b%3A0x3165428546d10e60!2sMauli%20Village!5e0!3m2!1sen!2sin!4v1704804726370!5m2!1sen!2sin" 
              width="100%"
              height={300}
              // frameBorder={0}
              style={{ border: 0 }}
              allowFullScreen=""
            />
            </div>
          </div>
        </div>
      </div>
      <div className="auto-container">
        <div className="boder-bottom-four" />
      </div>
      <div className="footer-bottom">
        <div className="auto-container">
          <div className="wrapper-box">
            <div className="copyright text-center">
              <div className="text">
                © Copyright Mauli Village Resort . All right reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer