import React ,{useLayoutEffect} from 'react'
import { FaCheck } from "react-icons/fa6";
import MetaTags from 'react-meta-tags';
import fire from "../Images/fire.jpeg";
import chooseUs from "../Images/fire.jpeg";
import gallery14 from '../Images/gallery14.jpg'
import gallery21 from "../Images/gallery21.jpg";
import gallery23 from "../Images/gallery23.jpg";
import gallery13 from "../Images/gallery13.jpg";
import NumberCounter from 'number-counter';
import aboutBanner from "../Images/Swimming-Pool-3.webp";
import treeHouse10 from "../Images/Treehouse-Ext-10-1.jpg";
import outDoor3 from "../Images/Outdoors-3-1.webp";
import outDoorLawn from "../Images/Outdoors-Lawn-Garden-Hammocks-2.webp";
import MaulivillageBuilding from "../Images/Maulivillage-Building-3.webp";

const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
    <>
    <MetaTags>
      <title>Resorts For Family in Alibaug | Mauli Village Resort</title>
        <meta title="Resorts For Family in Alibaug | Mauli Village Resort" />
        <meta
          name="description"
          content="Create cherished family moments at the finest Resorts for Family in Alibaug. Enjoy a perfect blend of comfort and fun, making your getaway a memorable. "
        />
        <meta
          name="keywords"
          content="Top Resorts in Alibaug, Resorts For Family in Alibaug,Budget-Friendly Resorts in Alibaug,Best Resorts with Private Pool in Alibaug,Best Resorts in Alibaug with Food,Best Resorts Near Alibaug"
        />
        <link rel="canonical" href="https://maulivillage.com/family-resorts-alibaug" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Resorts For Family in Alibaug | Mauli Village Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maulivillage.com/family-resorts-alibaug"/>
        <meta property="og:description" content="Create cherished family moments at the finest Resorts for Family in Alibaug. Enjoy a perfect blend of comfort and fun, making your getaway a memorable. " />
        <meta property="og:image" content="https://maulivillage.com/" />       
      </MetaTags>
      
  <div className="page-wrapper">
    {/* Main Header */}
    <header className="main-header header-style-one">
    </header>
    
    <section
      className="page-title"
      style={{
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${aboutBanner})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: "100% 100%"
  }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Resorts For Family in Alibaug</h1>
        </div>
      </div>
    </section>
    {/* Welcome Section six */}
    <section className="welcome-section-six light-bg mx-60 border-shape-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="title-box">
              <div className="sub-title">Welcome To Mauli Resort</div>
              <h2 className="sec-title small mb-30">
              From Vision to Vacation: The Mauli Resort Experience <br />
                
              </h2>
              <div className="text-two">
              Resorts for family in Alibaug: Begin a transformative journey at Mauli Resort, where each moment turns into a cherished memory. Delve into the captivating landscapes of Alibaug, making it an ideal destination for family getaways.
              {/* Embark on a transformative journey with Mauli Resort, where every moment becomes a memory. Immerse in the enchanting landscapes of Alibaug, an ideal resort for family getaways. */}
              <br/> 
              At Mauli Village, relish delectable homemade veg and non-veg cuisine in cozy rooms surrounded by serene landscapes. Our skilled kitchen crew ensures a delightful culinary experience, including morning/evening tea, breakfast, lunch, and dinner.
              <br/> 
              The warm smiles of our staff add a special touch, making Mauli Village the perfect venue for birthdays, family picnics, student groups, or cherished moments with loved ones. Nestled along the Mandwa-Alibag road, our beautiful resort invites you to create memories in any season.
              <br/> 
              Discover beyond delectable homemade cuisine at Mauli Resort, where an array of services awaits, such as a swimming pool, baby pool, tennis court, cricket ground, and more. Immerse yourself in the Mauli Resort experience, where your dream of an ideal family retreat comes to fruition. Your ultimate getaway awaits your arrival!
              {/* Beyond exquisite homemade food, Mauli Resort offers a range of services, including a swimming pool, baby pool, tennis court, cricket ground, and more. Experience the Mauli Resort, where your vision of a perfect family retreat comes to life. Resorts for family in Alibaug await your arrival! */}
           
              </div>
             
            </div>
          </div>
          <div className="col-lg-6">
            <div className="text-lg-end">
              <div className="image">
                <img src={treeHouse10} alt="Resorts For Family " />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="why-choose-us-section light-bg  pt-0 border-shape-bottom pt-5">
      <div className="container">
        <div className="title-box text-center mb-5">
          <div className="sub-title">Our Features</div>
          <h2 className="sec-title mb-30">Why Choose Us</h2>
        </div>
        <div className="row">
          <div className="col-lg-6 why-choose-us-block">
            <div className="image">
              <img src={outDoor3} alt=" Family in Alibaug" />
            </div>
            <div className="inner-box">
              <div className="image-block">
                <img src={outDoor3} alt="Resorts For Family " />
              </div>
              <div className="text-three">
                <span>1350 +</span> Visiters <br /> Come Annunally
              </div>
            </div>
          </div>
          <div className="col-lg-6 why-choose-us-block">
            <div className="text-two">
            Choose Mauli Resort for an exceptional retreat in Alibaug, offering picturesque landscapes, cozy accommodations, and delectable cuisine. Our dedicated team ensures a memorable stay, whether for special occasions, family picnics, or quality time with loved ones. Conveniently located off the Mandwa-Alibag road, we provide various amenities, including a swimming pool, baby pool, tennis court, cricket ground, and more. Opt for an unforgettable getaway where our warm hospitality and Alibaug's scenic charm combine for an extraordinary experience, making Mauli Resort the premier choice among family resorts in the area.
            </div>
            <div className="icon-list">
              <ul>
                <li>
                  <i className="flaticon-checkmark" />
                  <FaCheck /> 	&nbsp;  24-Hour Room Service
                </li>
                <li>
                  <i className="flaticon-checkmark" />
                  <FaCheck /> 	&nbsp; Full WiFi Access
                </li>
                <li>
                  <i className="flaticon-checkmark" />
                  <FaCheck /> 	&nbsp; Hi-Class Security
                </li>
                <li>
                  <i className="flaticon-checkmark" />
                  <label> <FaCheck /> </label>	&nbsp; Spacious Parking Area
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* about section two */}
    <section className="about-section-two dark_bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="title-text">
              <div className="sub-title">about us</div>
              <h2 className="sec-title ">
                The Story of Behind <br /> Our Resort
              </h2>
            </div>
            <div className="text tsbor">
            Resorts for Family in Alibaug: Discover our nighttime specialty at Mauli Resort, where nature seamlessly integrates with thoughtfully crafted lighting, creating a unique and soothing atmosphere. Whether leisurely strolling through the grounds or unwinding in the comfort of your accommodations, the night at Mauli Resort is a celebration of tranquility, providing an unforgettable escape under the starlit Alibaug sky for families seeking the perfect retreat.
            </div>
            <div className="image">
              <img src={outDoorLawn} alt=" Family in Alibaug" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="image-two">
              <img src={MaulivillageBuilding} alt="Resorts For Family " />
            </div>
            <div className="text-two tsbor">
            Resorts for Family in Alibaug: Witness Mauli Resort's enchanting nocturnal charm as daylight gracefully transitions to night. Bathed in a symphony of lights, our landscape creates a magical ambiance, transforming your stay into a memorable and ethereal experience. The night brings a special allure to Mauli Resort, where each moment is illuminated by the captivating beauty of the surroundings, ensuring a serene and enchanting retreat for families at one of the finest resorts for family in Alibaug.
{" "}
            </div>
          </div>
        </div>
      </div>
    </section>
  
  </div>
  <div className="scroll-to-top scroll-to-target" data-target="html">
    <span className="fas fa-arrow-up" />
  </div>
</>

    </>
  )
}

export default About