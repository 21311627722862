import React from 'react'
// import "../Css/SightSeeing.css";

const SightSeeing = () => {
  return (
    <>
    <section
  className="elementor-section elementor-top-section elementor-element elementor-element-e186988 elementor-section-height-min-height elementor-section-boxed elementor-section-height-default elementor-section-items-middle"
  data-id="e186988"
  data-element_type="section"
  data-settings='{"background_background":"classic"}'
>
  {" "}
  <div className="elementor-container elementor-column-gap-default">
    {" "}
    <div
      className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4f5af1e"
      data-id="4f5af1e"
      data-element_type="column"
    >
      {" "}
      <div className="elementor-widget-wrap elementor-element-populated">
        {" "}
        <section
          className="elementor-section elementor-inner-section elementor-element elementor-element-7089ade elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="7089ade"
          data-element_type="section"
        >
          {" "}
          <div className="elementor-container elementor-column-gap-default">
            {" "}
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-64eead9"
              data-id="64eead9"
              data-element_type="column"
            >
              {" "}
              <div className="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  className="elementor-element elementor-element-0230e4b elementor-widget elementor-widget-elementskit-image-box"
                  data-id="0230e4b"
                  data-element_type="widget"
                  data-widget_type="elementskit-image-box.default"
                >
                  {" "}
                  <div className="elementor-widget-container">
                    {" "}
                    <div className="ekit-wid-con">
                      {" "}
                      <div className="elementskit-info-image-box ekit-image-box text-left floating-style">
                        {" "}
                        <div className="elementskit-box-header image-box-img-left">
                          {" "}
                          <img
                            width={520}
                            height={347}
                            alt=""
                            sizes="(max-width: 520px) 100vw, 520px"
                            nitro-lazy-srcset="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team28.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team28-300x200.jpg 300w"
                            nitro-lazy-src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team28.jpg"
                            className="attachment-full size-full lazyloaded"
                            decoding="async"
                            nitro-lazy-empty=""
                            id="NjA2OjM3Mw==-1"
                            src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team28.jpg"
                            srcSet="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team28.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team28-300x200.jpg 300w"
                          />{" "}
                        </div>{" "}
                        <div className="elementskit-box-body ekit-image-box-body">
                          {" "}
                          <div className="elementskit-box-content ekit-image-box-body-inner">
                            {" "}
                            <h3 className="elementskit-info-box-title">
                              {" "}
                              Kihim Beach{" "}
                            </h3>{" "}
                            <div className="elementskit-box-style-content">
                              {" "}
                              Kihim beach is one of the famous beaches in
                              Alibag. As it is away from the main city, it is
                              less crowded than Alibag beach. It is also near
                              Mauli village(Approx km 3 km).{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-8bed4ca"
              data-id="8bed4ca"
              data-element_type="column"
            >
              {" "}
              <div className="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  className="elementor-element elementor-element-53d9971 elementor-widget elementor-widget-elementskit-image-box"
                  data-id="53d9971"
                  data-element_type="widget"
                  data-widget_type="elementskit-image-box.default"
                >
                  {" "}
                  <div className="elementor-widget-container">
                    {" "}
                    <div className="ekit-wid-con">
                      {" "}
                      <div className="elementskit-info-image-box ekit-image-box text-left floating-style">
                        {" "}
                        <div className="elementskit-box-header image-box-img-left">
                          {" "}
                          <img
                            width={520}
                            height={347}
                            alt=""
                            sizes="(max-width: 520px) 100vw, 520px"
                            nitro-lazy-srcset="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team29.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team29-300x200.jpg 300w"
                            nitro-lazy-src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team29.jpg"
                            className="attachment-full size-full lazyloaded"
                            decoding="async"
                            nitro-lazy-empty=""
                            id="NjM3OjM4OA==-1"
                            src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team29.jpg"
                            srcSet="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team29.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team29-300x200.jpg 300w"
                          />{" "}
                        </div>{" "}
                        <div className="elementskit-box-body ekit-image-box-body">
                          {" "}
                          <div className="elementskit-box-content ekit-image-box-body-inner">
                            {" "}
                            <h3 className="elementskit-info-box-title">
                              {" "}
                              Karmarkar Museum{" "}
                            </h3>{" "}
                            <div className="elementskit-box-style-content">
                              {" "}
                              While you are staying at Mauli Village, you must
                              visit Karmarkar Museum which is located
                              approximately 18 kms from Alibaug town on the
                              Alibaug-Rewas Road. Beautifully curved Sculptors
                              are made by Mr. Karmarkar Which is displayed in an
                              old bungalow.{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-03d3562"
              data-id="03d3562"
              data-element_type="column"
            >
              {" "}
              <div className="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  className="elementor-element elementor-element-a34ef00 elementor-widget elementor-widget-elementskit-image-box"
                  data-id="a34ef00"
                  data-element_type="widget"
                  data-widget_type="elementskit-image-box.default"
                >
                  {" "}
                  <div className="elementor-widget-container">
                    {" "}
                    <div className="ekit-wid-con">
                      {" "}
                      <div className="elementskit-info-image-box ekit-image-box text-left floating-style">
                        {" "}
                        <div className="elementskit-box-header image-box-img-left">
                          {" "}
                          <img
                            width={520}
                            height={347}
                            alt=""
                            sizes="(max-width: 520px) 100vw, 520px"
                            nitro-lazy-srcset="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team30.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team30-300x200.jpg 300w"
                            nitro-lazy-src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team30.jpg"
                            className="attachment-full size-full lazyloaded"
                            decoding="async"
                            nitro-lazy-empty=""
                            id="NjcyOjM4OA==-1"
                            src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team30.jpg"
                            srcSet="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team30.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team30-300x200.jpg 300w"
                          />{" "}
                        </div>{" "}
                        <div className="elementskit-box-body ekit-image-box-body">
                          {" "}
                          <div className="elementskit-box-content ekit-image-box-body-inner">
                            {" "}
                            <h3 className="elementskit-info-box-title">
                              {" "}
                              Kankeshwar Temple{" "}
                            </h3>{" "}
                            <div className="elementskit-box-style-content">
                              {" "}
                              If you love trekking, then this place is the best
                              option for you when you visit Beautiful Alibag.
                              Kankeshwar temple is situated on a small hill and
                              is famous for its cool climate and the old temple
                              of Lord Shiva. It is almost 12 km and 15 km from
                              the main town and km from Mauli Village.{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
        <section
          className="elementor-section elementor-inner-section elementor-element elementor-element-a9d4fe9 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="a9d4fe9"
          data-element_type="section"
        >
          {" "}
          <div className="elementor-container elementor-column-gap-default">
            {" "}
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2b0285c"
              data-id="2b0285c"
              data-element_type="column"
            >
              {" "}
              <div className="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  className="elementor-element elementor-element-a911f65 elementor-widget elementor-widget-elementskit-image-box"
                  data-id="a911f65"
                  data-element_type="widget"
                  data-widget_type="elementskit-image-box.default"
                >
                  {" "}
                  <div className="elementor-widget-container">
                    {" "}
                    <div className="ekit-wid-con">
                      {" "}
                      <div className="elementskit-info-image-box ekit-image-box text-left floating-style">
                        {" "}
                        <div className="elementskit-box-header image-box-img-left">
                          {" "}
                          <img
                            width={520}
                            height={347}
                            alt=""
                            sizes="(max-width: 520px) 100vw, 520px"
                            nitro-lazy-srcset="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-2.png 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-2-300x200.png 300w"
                            nitro-lazy-src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-2.png"
                            className="attachment-full size-full lazyloaded"
                            decoding="async"
                            nitro-lazy-empty=""
                            id="NzEyOjQyMQ==-1"
                            src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-2.png"
                            srcSet="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-2.png 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-2-300x200.png 300w"
                          />{" "}
                        </div>{" "}
                        <div className="elementskit-box-body ekit-image-box-body">
                          {" "}
                          <div className="elementskit-box-content ekit-image-box-body-inner">
                            {" "}
                            <h3 className="elementskit-info-box-title">
                              {" "}
                              water sports{" "}
                            </h3>{" "}
                            <div className="elementskit-box-style-content">
                              {" "}
                              You can enjoy various adventurous water sports
                              like Parasailing, Jet Skiing, Banana Boat riding,
                              and many more at Kihim beach.{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-4071238"
              data-id={4071238}
              data-element_type="column"
            >
              {" "}
              <div className="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  className="elementor-element elementor-element-a157c49 elementor-widget elementor-widget-elementskit-image-box"
                  data-id="a157c49"
                  data-element_type="widget"
                  data-widget_type="elementskit-image-box.default"
                >
                  {" "}
                  <div className="elementor-widget-container">
                    {" "}
                    <div className="ekit-wid-con">
                      {" "}
                      <div className="elementskit-info-image-box ekit-image-box text-left floating-style">
                        {" "}
                        <div className="elementskit-box-header image-box-img-left">
                          {" "}
                          <img
                            width={520}
                            height={347}
                            alt=""
                            sizes="(max-width: 520px) 100vw, 520px"
                            nitro-lazy-srcset="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-5.png 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-5-300x200.png 300w"
                            nitro-lazy-src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-5.png"
                            className="attachment-full size-full lazyloaded"
                            decoding="async"
                            nitro-lazy-empty=""
                            id="NzQzOjQyMQ==-1"
                            src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-5.png"
                            srcSet="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-5.png 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/Untitled-design-5-300x200.png 300w"
                          />{" "}
                        </div>{" "}
                        <div className="elementskit-box-body ekit-image-box-body nitro-offscreen">
                          {" "}
                          <div className="elementskit-box-content ekit-image-box-body-inner">
                            {" "}
                            <h3 className="elementskit-info-box-title">
                              {" "}
                              Mandwa{" "}
                            </h3>{" "}
                            <div className="elementskit-box-style-content">
                              {" "}
                              A popular weekend beach destination from Mumbai
                              city. It is very accessible because of the direct
                              ferry services which are available near Mandwa
                              beach to and from Mumbai city.{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div
              className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-e327700"
              data-id="e327700"
              data-element_type="column"
            >
              {" "}
              <div className="elementor-widget-wrap elementor-element-populated">
                {" "}
                <div
                  className="elementor-element elementor-element-9af0372 elementor-widget elementor-widget-elementskit-image-box"
                  data-id="9af0372"
                  data-element_type="widget"
                  data-widget_type="elementskit-image-box.default"
                >
                  {" "}
                  <div className="elementor-widget-container">
                    {" "}
                    <div className="ekit-wid-con">
                      {" "}
                      <div className="elementskit-info-image-box ekit-image-box text-left floating-style">
                        {" "}
                        <div className="elementskit-box-header image-box-img-left">
                          {" "}
                          <img
                            width={520}
                            height={347}
                            alt=""
                            sizes="(max-width: 520px) 100vw, 520px"
                            nitro-lazy-srcset="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team31.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team31-300x200.jpg 300w"
                            nitro-lazy-src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team31.jpg"
                            className="attachment-full size-full lazyloaded"
                            decoding="async"
                            nitro-lazy-empty=""
                            id="Nzc0OjM4OA==-1"
                            src="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team31.jpg"
                            srcSet="https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team31.jpg 520w, https://cdn-hhmnh.nitrocdn.com/biBdMYNBscqRvCDdenKgHRXBNyfXBskv/assets/images/optimized/rev-c19631a/wp-content/uploads/2022/11/team31-300x200.jpg 300w"
                          />{" "}
                        </div>{" "}
                        <div className="elementskit-box-body ekit-image-box-body nitro-offscreen">
                          {" "}
                          <div className="elementskit-box-content ekit-image-box-body-inner">
                            {" "}
                            <h3 className="elementskit-info-box-title">
                              {" "}
                              Alibaug Beach{" "}
                            </h3>{" "}
                            <div className="elementskit-box-style-content">
                              {" "}
                              Alibag is well-known for neat beaches, Fish Thali,
                              Temples, Forts, and many more attractive things.
                              There are several tourist places on and around the
                              beach but the main attraction is Kolaba Fort built
                              by the great Chhatrapati Shivaji Maharaj. The
                              sandy beach with coconut trees lined up enhances
                              the beauty of this place.{" "}
                            </div>{" "}
                          </div>{" "}
                        </div>{" "}
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </section>{" "}
      </div>{" "}
    </div>{" "}
  </div>{" "}
</section>


    </>
  )
}

export default SightSeeing