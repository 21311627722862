import React from 'react'
import '../Pages/Css/CoomingSoon.css';
import events from "../Images/events.webp";
const CoomingSoon = () => {
  return (
    <>
        
    <div className="page-wrapper">
  <div>
      <div className='container'>
        <div className='cofeemargin'>
        <div className='row ' >
        <div className="col-md-6 border1">
           
            <h1 className='hade1' >
                Coming Soon
            </h1>
            
            <ol class=" list-group-numbered">
            <li class="list-group-item">Corporate Events</li>
            <li class="list-group-item">Birthday Parties</li>
            <li class="list-group-item">Marriage Reception etc</li>
            </ol>
         </div>
         <div className="col-md-6">
         <img className='codfeimage' src={events} alt='coming soon'></img>
         </div>
      </div>
      </div>
      </div>
    </div>


    
  
    
  </div>
    </>
  )
}

export default CoomingSoon