import React,{useLayoutEffect} from 'react'
import MetaTags from 'react-meta-tags';

import cuisine from "../Images/cuisine.webp";
import FoodSlider from './FoodSlider';
import cuisine1 from "../Images/cuisine_50.webp";

const Cuisine = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
    <MetaTags>
      <title>Best Resorts in Alibaug with Food | Mauli Village Resort</title>
        <meta title="Best Resorts in Alibaug with Food | Mauli Village Resort" />
        <meta
          name="description"
          content="Embark on a culinary journey at the Best Resorts in Alibaug with Food that transcends taste. Indulge in exquisite dining, where luxury meets gastronomy."
        />
        <meta
          name="keywords"
          content="Top Resorts in Alibaug, Resorts For Family in Alibaug,Budget-Friendly Resorts in Alibaug,Best Resorts with Private Pool in Alibaug,Best Resorts in Alibaug with Food,Best Resorts Near Alibaug"
        />
        <link rel="canonical" href="https://maulivillage.com/best-alibaug-resorts-food" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Resorts in Alibaug with Food | Mauli Village Resort" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maulivillage.com/best-alibaug-resorts-food"/>
        <meta property="og:description" content="Embark on a culinary journey at the Best Resorts in Alibaug with Food that transcends taste. Indulge in exquisite dining, where luxury meets gastronomy." />
        <meta property="og:image" content="https://maulivillage.com/" />       
      </MetaTags>
      <p className='seo-para'>
        Embark on a culinary journey at Mauli Resort, renowned as one of the Best Resorts in Alibaug with Food that surpasses expectations. In our dedicated cuisine section, we proudly present a delightful showcase of our culinary offerings, emphasizing our commitment to providing an exceptional dining experience.
        The images curated in this section reflect the diverse and delectable array of dishes that define Mauli Resort as one of the Best Resorts in Alibaug with Food. From exquisite traditional flavors to international cuisines, each snapshot captures the essence of our gastronomic excellence.
        As you peruse the gallery, envision yourself savoring every bite of our meticulously crafted dishes. The repeated assurance that Mauli Resort stands among the Best Resorts in Alibaug with Food echoes in every image, highlighting our dedication to culinary perfection. Our skilled chefs artfully prepare each dish, ensuring that your dining experience is a harmonious blend of flavors and presentation.
        The cuisine section serves as a visual testament to the diverse culinary offerings available at Mauli Resort. Whether you prefer local delicacies or international fare, our resort stands as a culinary haven among the Best Resorts in Alibaug with Food, catering to every palate and preference.
        Enrich your stay at Mauli Resort by exploring our cuisine section, where the fusion of taste, aesthetics, and quality converges to create a dining experience that is as memorable as it is flavorful. Immerse yourself in the culinary excellence that sets us apart as one of the Best Resorts in Alibaug with Food, promising a feast for the senses with every visit.
      </p>
    <div className="page-wrapper">
    <section
      className="page-title"
      style={{
    backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${cuisine})`,
    // padding: "150px",
    backgroundRepeat: 'no-repeat',
    backgroundSize: "100% 100%"
  }}
    >
      <div className="auto-container">
        <div className="text-center">
          <h1>Best Resorts in Alibaug with Food</h1>
        </div>
      </div>
    </section>
    <section className="why-choose-us-section light-bg mx-60 pt-0 border-shape-bottom pt-5">
      <div className="container">
        <div className="title-box text-center">
          <div className="sub-title">Cooking Style</div> 
          <h2 className="sec-title mb-30">Cuisine</h2>
        </div>
        <p className='d-none'> Best Resorts in Alibaug with Food</p>
        <div className="row">
        <div className="col-lg-6 why-choose-us-block mt-5 mt-md-1">
  <section className='my-5'>
    <FoodSlider />
  </section>
</div>
          <div className="col-lg-6 why-choose-us-block my-auto">
          <div className="content-block">
              <div className="title-box">
                <h4 className="sec-title">
                Authentic Maharashtra Food
                </h4>
              </div>
              <div className="text">
              Indulge in the culinary delights of Maharashtra at Mauli Resort, where our guests experience an unparalleled love for our food, bringing them back time and again. Our commitment to authenticity sets us apart as one of the Best Resorts in Alibaug with Food.
              
                At Mauli Resort, we take pride in serving the finest Maharashtra cuisine, celebrated for its authentic charm. Immerse yourself in the rich flavors of Konkani-style dishes, expertly crafted to tantalize your taste buds. Our menu boasts a diverse selection, ranging from mouthwatering vegetarian options to delectable non-vegetarian specialties. However, our true pride lies in the exquisite seafood offerings sourced from the bountiful Konkan belt.
                           
                Join us at Mauli Resort, where every dish tells a story, and every bite is a celebration of Maharashtra's culinary excellence.
              </div>
              <p className='d-none'> Best Resorts in Alibaug with Food</p>
            </div>
            
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 why-choose-us-block">
          
            <div className="text-two">
            <div className="title-box">
                <h4 className="sec-title">
                 Food Timing
                </h4>
              </div>
            <div>
            <table className="table table-text mt-5">
  <thead>
    <tr>
      <th scope="col">Food</th>
      <th scope="col">From</th>
      <th scope="col">To</th>
    </tr>
  </thead>
  <tbody >
    <tr>
      <td> Breakfast </td>
      <td> 09:00 am </td>
      <td> 10:00 am </td>
    </tr>
    <tr>
      <td>Lunch</td>
      <td> 12:30 pm </td>
      <td> 02:30 pm</td>
    </tr>
    <tr>
      <td> Dinner</td>
      <td> 08:30 pm </td>
      <td> 10:00 pm</td>
    </tr>
  </tbody>
</table>

            </div>
            </div>
          </div>
          <div className="col-lg-6 why-choose-us-block">
          <div className="image">
              <img src={cuisine1} alt="Best Resorts in Alibaug " />
            </div>
            
          </div>
        </div>
       
      </div>
    </section>
    
  </div>
    </>
  )
}

export default Cuisine