import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { TiEyeOutline } from "react-icons/ti";
import out1 from "../Images/gallery/OUTDOORS-1_50.webp";
import out2 from "../Images/gallery/OUTDOORS-2_50.webp";
import out3 from "../Images/gallery/OUTDOORS-3_50.webp";
import out4 from "../Images/gallery/OUTDOORS-4_50.webp";
import out5 from "../Images/gallery/OUTDOORS-5_50.webp";
import out6 from "../Images/gallery/OUTDOORS-6_50.webp";
import out7 from "../Images/gallery/OUTDOORS-7_50.webp";
import out8 from "../Images/gallery/OUTDOORS-8_50.webp";
import out9 from "../Images/gallery/OUTDOORS-9_50.webp";
import out10 from "../Images/gallery/OUTDOORS-10_50.webp";
import out11 from "../Images/gallery/OUTDOORS-11_50.webp";
import out12 from "../Images/gallery/OUTDOORS-12_50.webp";
import out13 from "../Images/gallery/OUTDOORS-13_50.webp";
import out14 from "../Images/gallery/OUTDOORS-14_50.webp";
import out15 from "../Images/gallery/OUTDOORS-15_50.webp";

const OutDoor = () => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = [
    { src: out1, title: 'Outdoor 1' },
    { src: out2, title: 'Outdoor 2' },
    { src: out3, title: 'Outdoor 3' },
    { src: out4, title: 'Outdoor 4' },
    { src: out5, title: 'Outdoor 5' },
    { src: out6, title: 'Outdoor 6' },
    { src: out7, title: 'Outdoor 7' },
    { src: out8, title: 'Outdoor 8' },
    { src: out9, title: 'Outdoor 9' },
    { src: out10, title: 'Outdoor 10' },
    { src: out11, title: 'Outdoor 11' },
    { src: out12, title: 'Outdoor 12' },
    { src: out13, title: 'Outdoor 13' },
    { src: out14, title: 'Outdoor 14' },
    { src: out15, title: 'Outdoor 15' },
  ];

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <section className="smile-section">
        <div className="container">
           <div className="row row-cols-md-4 row-cols-sm-2 row-cols-1">
      {images.map((image, index) => (
        <div className="col mb-md-5 mb-5" key={index}>
          <div className="smile-img" onClick={() => openLightbox(index)}>
            <img src={image.src} alt={`Best Resorts with Private Pool in Alibaug ${index + 1}`} />
            <div className="smile-icon">
              <TiEyeOutline />
            </div>
          </div>
        </div>
      ))}
      {lightboxOpen && (
        <Lightbox
          mainSrc={images[photoIndex].src}
          nextSrc={images[(photoIndex + 1) % images.length].src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
        </div>
      </section> 
  )
}

export default OutDoor;
