import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function TestimonialSlider() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[ Autoplay, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="testimonial-block">
                <h3  className='mt-3'>
                  <span className="quote">“</span>The Best Resort
                </h3>
                <div className="text">
                The place has nice farm type surroundings. The cooks prepare really yummy food and they provide bisleri mineral water for drinking always ..The lady manager there is very accommodating with our requests regarding food options, timings , room type etc .. had superb good experience overall .. definitely recommended for families with kids who have own vehicle to go nearby beaches .
                    {" "}
                </div>
                <div className="author-info">
                  <div className="author-thumb">
                  </div>
                  <div className="">
                   <label className='name'> Varsha Kulkarni </label><br/> <label className='text d-block'>8 January 2024  </label>
                  </div>  
                </div>
                
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial-block">
                <h3 className='mt-3'>
                  <span className="quote">“</span>The Best Resort
                </h3>
                <div className="text">
                We had a delightful stay at the Alibaug Treehouse. This unique tree-top accommodation offered comfort and memorable experiences. The room's cleanliness, two balconies with stunning views, and the well-maintained property impressed us. Special mention to the gracious and attentive lady manager who ensured our every need was met. The home-cooked food was a delicious highlight, tailored to our preferences. Eagerly looking forward to our next visit!

{" "}
                </div>
                <div className="author-info">
                  <div className="author-thumb">
                  </div>
                  <div className="">
                   <label className='name'> Revan Upasani </label><br/> <label className='text d-block'>24 September 2023 </label>
                  </div>  
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial-block">
                <h3 className='mt-3'>
                  <span className="quote">“</span>The Best Resort
                </h3>
                <div className="text">
                I spent three nights with family and had a blast all around. From the minute we checked in, the hotel personnel and housekeeping service made our stay enjoyable and unforgettable. The fact that the kids pool area is located inside the main pool makes it much easier to watch over kids. The prices are reasonable and the view is spectacular.

                    {" "}
                </div>
                <div className="author-info">
                  <div className="author-thumb">
                  </div>
                  <div className="">
                   <label className='name'> Kaustubh Nathuram Jadhav </label><br/> <label className='text d-block'>19 June 2023 </label>
                  </div>  
                </div>
              </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="testimonial-block">
                <h3 className='mt-3'>
                  <span className="quote">“</span>The Best Resort
                </h3>
                <div className="text">
                Great place .. must go with family... big and clean swimming pool big garden.. testy food little bit higher side but worth it .. spacious rooms neat and clean bathrooms.. you can play box cricket badminton carrom swings..
                Drinks are not allowed in open or in garden only in room. Location is near to beach ..
                Must go with big family and group..

                    {" "}
                </div>
                <div className="author-info">
                  <div className="author-thumb">
                  </div>
                  <div className="">
                   <label className='name'> Kaustubh Nathuram Jadhav </label><br/> <label className='text d-block'>20 August 2023 </label>
                  </div>
                </div>
              </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
